import { h1 } from '@/layout/GlobalStyles';
import mail from '@a/mail.svg';
import phone from '@a/phone.svg';
import address from '@a/pin.svg';
import styled from '@emotion/styled';
import ContactForm from './ContactForm';
import { PrimaryLgBtn } from './Buttons/PrimaryLgBtn';
import { css } from '@emotion/react';

const FormContainer = styled.div`
    padding: 32px 32px 48px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 33%;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.darkGreen};
        z-index: -1;
    }

    h2 {
        ${h1};
        margin: 32px 0;
        color: ${({ theme }) => theme.white};
    }

    p,
    a {
        color: ${({ theme }) => theme.white};
        display: block;
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: space-between;
        margin: 72px 32px 48px;
        padding: 48px;
        gap: 32px;
        &::before {
            bottom: 0;
        }
    }
    @media (min-width: 1280px) {
        padding: 40px 40px 40px 104px;
        max-width: 1280px;
        margin: 72px auto 48px;
    }
`;

const Tagline = styled.p`
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
`;

const InfoContainer = styled.div`
    margin-bottom: 56px;

    > h3 {
        margin-bottom: 24px;
        color: ${({ theme }) => theme.white};
    }

    > a {
        transition: opacity 0.3s ease-in-out;

        :hover,
        :focus-visible {
            opacity: 0.7;
        }
    }

    @media (min-width: 1024px) {
        margin-top: 144px;
        margin-bottom: 0;
    }
`;

const Address = styled.p`
    padding-left: 34px;
    position: relative;
    margin: 0 0 16px;

    &::before {
        content: '';
        width: 18px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url(${address}) no-repeat center;
    }
`;

const Phone = styled.a`
    padding-left: 34px;
    position: relative;
    margin-bottom: 16px;

    &::before {
        content: '';
        width: 18px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url(${phone}) no-repeat center;
    }
`;

const Mail = styled.a`
    padding-left: 34px;
    margin-bottom: 16px;
    position: relative;

    &::before {
        content: '';
        width: 18px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url(${mail}) no-repeat center;
    }
`;

const ContactDetails = () => {
    return (
        <FormContainer>
            <div>
                <Tagline>contact us</Tagline>
                <h2>Appointments or Questions.</h2>
                <InfoContainer>
                    <h3>Contact Info:</h3>
                    <Address
                    // href="https://g.page/Integrate_Institute?share"
                    // target="_blank"
                    // rel="noreferrer"
                    >
                        1103 West Burnsville Pkwy Suite 120 Burnsville, MN 55337
                    </Address>
                    <Phone href="tel:+19523001461">952-300-1461</Phone>
                    <Mail href="mailto:team@integrateinstitute.com">
                        team@integrateinstitute.com
                    </Mail>
                    <hr
                        css={css`
                            border: none;
                            border-top: 1px solid;
                            border-image-slice: 1;
                            border-image-source: linear-gradient(180deg, #ffffff 0%, #f8fffa 100%);
                            max-width: 277px;
                            margin: 32px 0;
                        `}
                    />
                    <h3
                        css={css`
                            margin: 0 0 16px !important;
                        `}
                    >
                        Already a Patient?
                    </h3>
                    <PrimaryLgBtn
                        as="a"
                        href="https://scheduling.go.promptemr.com/onlineScheduling?w=171&s=DL"
                        target="_blank"
                        rel="noreferrer"
                        css={css`
                            width: fit-content;
                            padding: 16px 64px 16px 32px;
                            text-transform: uppercase;

                            ::before,
                            ::after {
                                opacity: 1;
                            }

                            ::after {
                                transform: none;
                            }
                        `}
                    >
                        Book an Appointment
                    </PrimaryLgBtn>
                </InfoContainer>
            </div>
            <ContactForm />
        </FormContainer>
    );
};

export default ContactDetails;
