import { PrimaryLgBtn } from '@/Buttons/PrimaryLgBtn';
import { Input, Label, Thanks } from '@/FormComponents';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';

const Form = styled.form`
    background: ${({ theme }) => theme.white};
    box-shadow: 0px 25px 55px rgba(126, 126, 126, 0.15);
    border-radius: 16px;
    padding: 72px 32px;
    position: relative;
    overflow: hidden;

    #contact-submit-btn {
        margin-top: 64px;
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        padding: 72px 96px;
    }
    @media (min-width: 1024px) {
        max-width: 600px;
        width: 80%;
        box-shadow: none;

        #contact-submit-btn {
            display: block;
            margin-left: auto;
        }
    }
`;

const ContactForm = () => {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmit(true);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Label
                htmlFor="contactName"
                css={css`
                    margin-top: 0;
                `}
                lightBg
            >
                Full Name
            </Label>
            <Input
                type="text"
                id="contactName"
                name="name"
                placeholder="Enter your full name"
                lightBg
            />
            <Label htmlFor="contactEmail" lightBg>
                Email Address*
            </Label>
            <Input
                type="email"
                id="contactEmail"
                name="email"
                placeholder="Enter your email address here"
                required
                lightBg
            />
            <Label htmlFor="contactMessage" lightBg>
                Message*
            </Label>
            <Input
                as="textarea"
                rows={10}
                id="contactMessage"
                name="message"
                placeholder="Write your message here"
                required
                lightBg
            />
            <PrimaryLgBtn
                css={css`
                    ::before {
                        opacity: 1;
                    }
                `}
                id="contact-submit-btn"
            >
                Send Message
            </PrimaryLgBtn>

            <Thanks submit={submit} lightBg>
                <h1>Thank you</h1>
                <h5>Someone will be right with you shortly!</h5>
            </Thanks>
        </Form>
    );
};

export default ContactForm;
