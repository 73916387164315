import ContactDetails from '@/ContactDetails';
import { px } from '@/layout/GlobalStyles';
import { Seo } from '@/layout/Seo';
import styled from '@emotion/styled';
import { Fragment } from 'react';

const ContactHero = styled.section`
    ${px};
    padding-top: 280px;
    padding-bottom: 180px;
    background: ${({ theme }) => theme.gradient};
`;

const TextBox = styled.div`
    margin-bottom: 96px;

    > p {
        color: ${({ theme }) => theme.white};

        :first-of-type {
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-weight: 700;
        }

        a {
            color: ${({ theme }) => theme.white};
            font-weight: 700;
            transition: opacity 0.3s ease-in-out;

            :hover {
                text-decoration: underline;
                opacity: 0.8;
            }
        }
    }

    > h1 {
        margin: 32px 0 24px;
        color: ${({ theme }) => theme.white};
    }

    @media (min-width: 1024px) {
        text-align: center;
        margin-bottom: 150px;
    }
`;
const ContactUs = () => {
    return (
        <Fragment>
            <Seo
                title="Contact Integrate Institute in Burnsville MN – Integrate Institute"
                description="Contact the team at integrate institute in Burnsville Minnesota and schedule your first visit today."
            />
            <ContactHero>
                <TextBox>
                    <p>contact us</p>
                    <h1>Let’s talk!</h1>
                    <p>The best way to schedule an appointment with us is to call or text.</p>
                </TextBox>
                <ContactDetails />
            </ContactHero>
        </Fragment>
    );
};

export default ContactUs;
